<style scoped>
.home {
  width: 100%;
  height: 100%;
  background: url("../assets/mainBg.jpg");
  background-size: 100% 100%;
}
.back img {
  position: absolute;
  top: 28px;
  left: 20px;
  width: 16pt;
  height: 16pt;
  /* margin: 28px 0 0 20px; */
}
.goAnswer {
  position: absolute;
  top: 28px;
  right: 20px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.title {
  font-size: 23px;
  width: 100%;
  height: 10vh;
  line-height: 10vh;
  color: #fff;
  font-weight: 500;
}
.rankingBody {
  width: 90%;
  height: 87vh;
  margin: 0 5%;
  background: #fff;
  border-radius: 5px;
}
.rankingBody .rankingHeader {
  height: 50px;
  box-sizing: border-box;
  padding-top: 15px;
  line-height: 35px;
  letter-spacing: 2px;
  position: relative;
  margin-bottom: 10px;
}
.rankingBody .rankingHeader > div.headerBox {
  display: inline-block;
  vertical-align: top;
  width: 10%;
  margin-right: 10%;
  font-size: 16px;
  font-weight: bold;
}
.rankingBody .rankingHeader > div.month {
  margin-right: 0;
}
.rankingBody .rankingHeader > div.lineActivity {
  width: 10%;
  height: 3px;
  border-radius: 8px;
  background: rgb(253, 70, 73);
  position: absolute;
  bottom: 0;
  transition: 0.2s;
}
.boxshow {
  box-shadow: 0px 0px 10px rgba(208, 35, 36, 0.05);
}
.activity {
  color: rgb(253, 70, 73);
}
.lineBody {
  width: 100%;
  height: 100%;
  padding-top: 10px;
}
.scrollDiv {
  height: calc(100% - 100px);
  overflow-y: scroll;
}
.rankingLine {
  width: 90%;
  height: 35px;
  line-height: 35px;
  background: #fff;
  box-shadow: 0 0 10px rgba(125, 125, 125, 0.17);
  margin: 5px auto;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 10px;
  overflow: hidden;
  position: relative;
}
.rankingLine > div {
  display: inline-block;
  vertical-align: top;
  width: 33.3%;
}
.rankingLine > span {
  display: inline-block;
  width: 20px;
  height: 15px;
  border-bottom-right-radius: 6px;
  font-size: 10px;
  line-height: 15px;
  background: rgb(253, 70, 73);
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.kickerBtn {
  width: 60%;
  height: 35px;
  margin: 0 auto;
  background: rgb(246, 180, 40);
  color: #fff;
  line-height: 35px;
  border-radius: 5px;
  font-size: 15px;
  margin-top: 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
</style>
<template>
  <div class="home" style="">
    <div class="back">
      <img @click="backMain" src="../assets/back.png" alt="" />
    </div>
    <div class="title">
      我要踢榜
    </div>
    <!-- <div class="goAnswer" @click="goAnswerDetail">
      踢榜
    </div> -->
    <div class="rankingBody boxshow">
      <div class="lineBody">
        <div class="rankingLine" style="background:rgb(253,70,73);color:#fff">
          <div>姓名</div>
          <div>得分</div>
          <div>用时</div>
        </div>
        <div class="scrollDiv">
          <div
            v-for="(item, index) in lineData"
            :key="index"
            class="rankingLine"
          >
            <div>{{ item.userName }}</div>
            <div>{{ item.score }}</div>
            <div>{{ formatTineMs(item.timeMs) }}</div>
            <span>{{ index + 1 }}</span>
          </div>
        </div>
        <div @click="goAnswerDetail" class="kickerBtn">
          我要踢榜
        </div>
      </div>
    </div>
    <!-- <div
      style="width:100%;height:7vh;color: #fff;font-size: 16px;line-height: 7vh;"
    >
      点击排行榜中的排名即可踢榜
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import { wxConPost } from "../api/httpApi"; // Data request API
import globalData from "../api/globalData"; // Data request API

export default {
  name: "Home",
  data() {
    return {
      showStatus: "day", // 显示排行榜的状态  day-天  week-周 month-月
      activityNum: 25, // 下划线的位置  25 45 65
      lineData: [],
      activity: {},
    };
  },

  components: {},
  methods: {
    goAnswerDetail() {
      this.$router.replace("/answer-detail");
    },
    // 转化毫秒
    formatTineMs(time) {
      const scond = parseInt(time / 1000);
      const showms = time % 1000;
      return `${scond}”${showms}`;
    },
    // 返回至首页
    backMain() {
      this.$router.replace("/activity-main");
    },
    // 切换显示排行榜
    changeShowRank(str) {
      if (str == "day") {
        this.activityNum = 25;
      } else if (str == "week") {
        this.activityNum = 45;
      } else if (str == "month") {
        this.activityNum = 65;
      }
      this.showStatus = str;
      // alert("功能正在开发！！");
    },
    goDetail(page) {
      this.$router.push(`/${page}`);
    },
    working() {
      alert("功能正在开发！！");
    },
  },
  async created() {
    this.activity = globalData.getActivity();
    if (!_.isEmpty(this.activity)) {
      const result = await wxConPost(
        "/services/gttt-answer-exam/rank/getRank",
        {
          id: this.activity.id,
          limit: 100,
          type: "pk",
        }
      );
      if (result.ret) {
        this.lineData = result.ret;
      }
    } else {
      this.backMain();
    }
  },
};
</script>
<style></style>
